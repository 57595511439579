<template>
	<NuxtLink
			:data-level="riskLevel"
			:to="riskDetailLink(risk.id)"
			class="risk"
	>
		<h3 class="cell title">
			<span class="truncate-title">
				<span
						v-if="risk.levelDeviationText"
						aria-hidden="true"
						class="level-deviation-info"
						:title="getDefaultRiskLevelText"
				>
					<IconSvg icon="help" />
				</span>

				{{ risk.title }}
			</span>
		</h3>
		<div :title="riskLevelText" class="level">
			<div class="sr-only">
				{{ riskLevelText }}
			</div>
		</div>
		<div v-if="showRootCauseNotice" class="notice">
			<ToolTip text="Risico heeft nog geen grondoorzaak">
				<IconSvg icon="alert-circle" />
			</ToolTip>
		</div>
		<div class="cell type">
			<BadgeLabel text="Risicotype" class="card-only" />
			<BaseBadge v-if="risk.type" :text="risk.type" />
		</div>
		<div class="cell status">
			<BadgeLabel text="Aantal acties" class="card-only" />
			<BaseBadge :text="risk.numberOfActions.toString()" badge-class="minimal" />
		</div>
		<div class="cell cta">
			<IconSvg icon="chevron-right" />
		</div>
	</NuxtLink>
</template>
<script lang="ts" setup>
	import {computed, type PropType} from 'vue';
	import type {RiskOverviewItemForFilters} from '~/lib/types/riskTypes';
	import {riskDetailLink} from '~/lib/pageHelper';
	import IconSvg from '~/components/generic/IconSvg.vue';
	import BaseBadge from '~/components/generic/badge/BaseBadge.vue';
	import {getRiskLevel, getRiskLevelText} from '~/lib/riskHelper';
	import BadgeLabel from '~/components/generic/badge/BadgeLabel.vue';
	import ToolTip from '~/components/generic/ToolTip.vue';

	const props = defineProps({
		risk: {
			type: Object as PropType<Pick<RiskOverviewItemForFilters, 'title' | 'type' | 'level' | 'levelDeviationText' | 'id' |
				'numberOfActions' | 'riskRootCauses'>>,
			required: true
		}
	});

	const riskLevel = computed(() => getRiskLevel(props.risk));

	const getDefaultRiskLevelText = computed(() => `Standaard risicoklasse: ${getRiskLevelText(props.risk.level)}`);

	const riskLevelText = computed(() => {
		const text = `Risicoklasse: ${getRiskLevelText(riskLevel.value)}`;
		if (!props.risk.levelDeviationText) {
			return text;
		}
		return `${text}\n${getDefaultRiskLevelText.value}`;
	});

	const showRootCauseNotice = computed(() => {
		return props.risk.riskRootCauses && props.risk.riskRootCauses.length === 0;
	});
</script>

<style scoped>
	.risk {
		--notice-icon-size: 20px;
		--icon-size: 16px;
		display: grid;
		grid-template: var(--risk-overview-grid);
		flex: 0 0 auto;
		width: 100%;
		max-width: 100%;
		background: #ffffff;
		font: inherit;
		color: inherit;
		text-decoration: none;
		font-size: var(--font-size-p-2);
		line-height: var(--line-height-p-2);
		position: relative;
		content-visibility: auto;
	}

	[data-level="HIGH"] {
		--card-border-color: var(--color-risk-high);
	}

	[data-level="MEDIUM"] {
		--card-border-color: var(--color-risk-medium);
	}

	[data-level="LOW"] {
		--card-border-color: var(--color-risk-low);
	}

	.title {
		grid-area: title;
		min-width: 0;
		max-width: 100%;
		font: inherit;
	}

	.risk:has(.notice) .title {
		padding-right: calc(1em + var(--notice-icon-size));
	}

	.notice {
		grid-area: title;
		grid-column: title-start / title-end;
		align-self: center;
		justify-self: end;
		--icon-size: var(--notice-icon-size);
		--icon-color: var(--color-warning);
	}

	.level {
		grid-area: level;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;

		background: var(--card-border-color);
		width: 4px;
		padding: 0;
	}

	.type {
		grid-area: type;
	}

	.status {
		grid-area: status;
		justify-items: center;
		align-items: center;
		text-align: center;
	}

	.cta {
		grid-area: cta;
		--icon-size: 24px;
		padding: 0;

		display: grid;

		align-items: stretch;
		justify-items: stretch;
		justify-self: end;
	}

	@media screen and (max-width: 869px) {
		.risk {
			padding: 14px 12px 8px 16px;
			position: relative;
			gap: 8px;

			align-items: center;
			justify-items: start;
		}

		.title {
			font-size: var(--font-size-p-2);
			line-height: var(--line-height-p-2);
			padding: 0 0 8px;
			margin: 0;
			font-weight: 500;
		}

		.notice {
			align-self: start;
		}

		.status {
			align-self: start;
			justify-self: start;
			text-align: left;
		}

		.cta {
			--icon-size: 24px;
			margin-top: -8px;
		}
	}

	@media screen and (min-width: 870px) {
		.cell {
			padding: var(--cell-padding-y, 20px) var(--cell-padding-x, 10px);
			white-space: nowrap;
			align-self: center;
		}

		.title {
			justify-self: auto;
			padding-left: var(--cell-padding-x);
			font-weight: normal;
		}

		.truncate-title {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			--line-clamp: 2;

			@supports (-webkit-line-clamp: var(--line-clamp)) {
				white-space: normal;
				display: -webkit-box;
				-webkit-line-clamp: var(--line-clamp);
				-webkit-box-orient: vertical;
				max-height: calc(1em * var(--line-height-p-2) * var(--line-clamp));
			}
		}

		.status {
			background: var(--color-grey-50);
			font-weight: 500;
			align-self: stretch;
			justify-self: stretch;
			display: grid;
			justify-items: center;
			align-items: center;
		}

		.cta {
			padding: 0;
			justify-self: center;
		}

		.card-only {
			display: none;
		}

		.risk:hover .title {
			font-weight: 600;
		}
	}
</style>
